import PropTypes from 'prop-types';
import cssVars from 'src/common/styles/variables';

/* eslint-disable max-len */
const BoostNextPlayIcon = ({
  width = '30px',
  height = '30px',
  viewBox = '0 0 48 48',
  backgroundfill = cssVars['--t-sharp'],
  innerFill = cssVars['--secondary-color-5'],
  className,
  dataTestId,
  onClick,
  ref,
}) => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    className={className}
    data-testid={dataTestId}
    onClick={onClick}
    ref={ref}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="48" height="48" rx="24" fill={backgroundfill} />
    <rect x="29" y="16.3335" width="3" height="16" fill={innerFill} />
    <path
      d="M18.6323 31.935C17.4676 32.7116 16.5195 32.3289 16.5195 31.0908V17.584C16.5195 16.3459 17.4676 15.9632 18.6323 16.7399L27.9501 22.9304C28.2212 23.0895 28.4424 23.3003 28.595 23.5451C28.7477 23.7899 28.8274 24.0616 28.8274 24.3374C28.8274 24.6132 28.7477 24.8849 28.595 25.1297C28.4424 25.3746 28.2212 25.5853 27.9501 25.7444L18.6323 31.935Z"
      fill={innerFill}
    />
  </svg>
);

BoostNextPlayIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  viewBox: PropTypes.string,
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  onClick: PropTypes.func,
};

export default BoostNextPlayIcon;
