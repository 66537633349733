import PropTypes from 'prop-types';
import cssVars from 'src/common/styles/variables';

/* eslint-disable max-len */
const ShareIcon = ({
  width = '30px',
  height = '30px',
  viewBox = '0 0 30 30',
  fill = cssVars['--secondary-color-5'],
  onClick,
  className,
  dataTestId,
}) => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    className={className}
    data-testid={dataTestId}
    onClick={onClick}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.5935 7.38096L14.9935 5L17.3935 7.38096"
      stroke={fill}
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path
      d="M15.0006 5V17.381"
      stroke={fill}
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path
      d="M10.5 9.76172H7V24.9998H23V9.76172H19.5"
      stroke={fill}
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
  </svg>
);

ShareIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  viewBox: PropTypes.string,
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  onClick: PropTypes.func,
};

export default ShareIcon;
