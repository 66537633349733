import PropTypes from 'prop-types';
import { getCssStyle } from 'src/common/utils/getCssStyle';

/* eslint-disable max-len */
const PlayErrorIcon = ({
  width,
  height,
  viewBox,
  onClick,
  className,
  dataTestId,
}) => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox || '0 0 14 14'}
    className={className}
    data-testid={dataTestId}
    onClick={onClick}
    fill={getCssStyle('--text-color')}
  >
    <path d="M7 0a7 7 0 100 14A7 7 0 007 0m0 1.56a5.45 5.45 0 11-.01 10.9A5.45 5.45 0 017 1.56" />
    <path d="M7.39 5.07H6.6a.39.39 0 01-.39-.4V3.9c0-.21.18-.39.4-.39h.77c.21 0 .39.18.39.4v.77c0 .21-.18.39-.4.39M7.39 10.11H6.6a.39.39 0 01-.39-.39v-3.5c0-.21.18-.39.4-.39h.77c.21 0 .39.18.39.4v3.5c0 .2-.18.38-.4.38" />
  </svg>
);

PlayErrorIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  viewBox: PropTypes.string,
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  onClick: PropTypes.func,
};

export default PlayErrorIcon;
