import {
  boostNextPlayButtonDesktopWidth,
  boostNextPlayButtonMobileWidth,
} from 'src/common/constants/boost';

export function getBoostNextPlayButtonWidth(isMediumBreakpoint) {
  return isMediumBreakpoint
    ? boostNextPlayButtonDesktopWidth
    : boostNextPlayButtonMobileWidth;
}
