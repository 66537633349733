import classNames from 'clsx';
import PropTypes from 'prop-types';
import { Component } from 'react';
import {
  SHARE,
  SHARE_AND_EMBED,
} from 'src/common/constants/localizations/profile';
import { COPY_URL } from 'src/common/constants/localizations/socialSharing';
import { sharePlatformNames } from 'src/common/constants/share/platforms';
import cssVariables from 'src/common/styles/variables';
import getEmbedPlayerUrl from 'src/common/utils/share/getEmbedPlayerUrl';
import getShareUrl from 'src/common/utils/share/getShareUrl';
import openSocialShareWindow from 'src/common/utils/window/openSocialShareWindow';
import { LocationAndLocalizationContext } from '../../../providers/LocationAndLocalizationProvider';
import Text from '../../Text';
import TooltipLegacy from '../TooltipLegacy';
import PillButton from '../button/PillButton';
import CommonDialog from './CommonDialog';
import shareDialogCss from './share-dialog.module.scss';

const shareDialogStyles = {
  dialog: {
    maxWidth: 451,
    position: 'relative',
  },
  dialogRoot: {
    zIndex: cssVariables['--above-top-z-index'],
  },
  dialogBody: {
    padding: 20,
    paddingTop: 65,
    paddingBottom: 35,
  },
};

function selectAndCopy(event) {
  event.target.select();
  document.execCommand('copy');
}

function onShareIconClick(getLocalizedText, shareMeta, sharePlatformName) {
  openSocialShareWindow({
    url: getShareUrl(getLocalizedText, shareMeta, sharePlatformName),
  });
}

export default class ShareDialog extends Component {
  static propTypes = {
    isActive: PropTypes.bool.isRequired,
    showEmbed: PropTypes.bool.isRequired,
    toggleShareDialog: PropTypes.func.isRequired,
    shareMeta: PropTypes.shape({
      guideId: PropTypes.string.isRequired,
      image: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      subtitle: PropTypes.string.isRequired,
      shareUrl: PropTypes.string.isRequired,
      shareText: PropTypes.string,
      slogan: PropTypes.string,
    }).isRequired,
  };

  static contextType = LocationAndLocalizationContext;

  copyShareUrl = () => {
    this.shareUrlEl.select();
    document.execCommand('copy');
  };

  render() {
    const { isActive, showEmbed, toggleShareDialog, shareMeta } = this.props;
    const { getLocalizedText } = this.context;

    return isActive ? (
      <CommonDialog
        dialogOpen
        dialogContentStyle={shareDialogStyles.dialog}
        bodyStyle={shareDialogStyles.dialogBody}
        handleDialogClose={toggleShareDialog}
        overlayClassName="shareDialogOverlay"
        data-testid="shareDialog"
        style={shareDialogStyles.dialogRoot}
        closeIconAttributes={{
          className: shareDialogCss.dialogXButton,
        }}
      >
        <div
          data-testid="shareDialogTitle"
          className={shareDialogCss.panelHeader}
        >
          <Text name={showEmbed ? SHARE_AND_EMBED : SHARE} />
        </div>
        <div className={shareDialogCss.stationInfoContainer}>
          <img
            data-testid="shareDialogStnImg"
            src={shareMeta.image}
            alt={shareMeta.title}
          />
          <div
            data-testid="shareDialogStnTitle"
            className={shareDialogCss.stationName}
          >
            {shareMeta.title}
          </div>
          <div
            data-testid="shareDialogStnSubtitle"
            className={shareDialogCss.stationGenre}
          >
            {shareMeta.slogan || shareMeta.subtitle}
          </div>
        </div>
        {showEmbed && (
          <div className={shareDialogCss.iframeContainer}>
            <input
              data-testid="shareDialogIframeInput"
              type="text"
              readOnly
              value={
                `<iframe src="${getEmbedPlayerUrl(
                  shareMeta.guideId,
                )}" style="width:100%; height:100px;" scrolling="no" frameborder="no"></iframe>` // eslint-disable-line
              }
              onClick={selectAndCopy}
            />
          </div>
        )}
        <div
          data-testid="shareIconsContainer"
          className={shareDialogCss.iconContainer}
        >
          <span
            className={classNames(
              shareDialogCss.shareIcon,
              shareDialogCss.facebook,
            )}
            data-testid="shareIconFacebook"
            onClick={() =>
              onShareIconClick(
                getLocalizedText,
                shareMeta,
                sharePlatformNames.FACEBOOK,
              )
            }
          />
          <span
            className={classNames(
              shareDialogCss.shareIcon,
              shareDialogCss.twitter,
            )}
            data-testid="shareIconTwitter"
            onClick={() =>
              onShareIconClick(
                getLocalizedText,
                shareMeta,
                sharePlatformNames.TWITTER,
              )
            }
          />
          <span
            className={classNames(
              shareDialogCss.shareIcon,
              shareDialogCss.tumblr,
            )}
            data-testid="shareIconTumblr"
            onClick={() =>
              onShareIconClick(
                getLocalizedText,
                shareMeta,
                sharePlatformNames.TUMBLR,
              )
            }
          />
        </div>
        <div>
          <input
            ref={(el) => (this.shareUrlEl = el)}
            className={shareDialogCss.shareUrlInput}
            value={shareMeta.shareUrl}
            readOnly
          />
          <PillButton
            className={shareDialogCss.copyUrlButton}
            id="copyUrlButton"
            dataTestId="copyUrlButton"
            onClick={this.copyShareUrl}
            label={<Text name={COPY_URL} />}
            tooltipId="copyShareUrlTooltip"
            tooltipText={shareMeta.shareUrl}
          />
          <TooltipLegacy tooltipId="copyShareUrlTooltip" />
        </div>
      </CommonDialog>
    ) : null;
  }
}
