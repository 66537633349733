import PropTypes from 'prop-types';
import cssVariables from 'src/common/styles/variables';
/* eslint-disable max-len */

const FastForwardThirtyIcon = ({
  dataTestId = 'fastForwardThirtyIcon',
  viewBox = '0 0 30 30',
  height = '30px',
  width = '30px',
  fill = cssVariables['--secondary-color-5'],
  className,
  onClick,
}) => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    data-testid={dataTestId}
    className={className}
    onClick={onClick}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.3604 9.33L16.1404 6.67L13.3604 4"
      stroke={fill}
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path
      d="M24.75 16.67C24.75 22.19 20.27 26.67 14.75 26.67C9.23 26.67 4.75 22.19 4.75 16.67C4.75 11.15 9.23 6.66998 14.75 6.66998"
      stroke={fill}
      strokeWidth="1.5"
      strokeLinecap="square"
      strokeLinejoin="round"
    />
    <path
      d="M9.32031 17.7L10.4603 17.49C10.6003 18.47 11.0103 18.96 11.9003 18.96C12.6003 18.96 13.0503 18.56 13.0503 17.95C13.0503 17.29 12.6303 16.95 11.8403 16.95H11.2603V16H11.7803C12.4403 16 12.8803 15.63 12.8803 15.15C12.8803 14.59 12.4803 14.23 11.8503 14.23C11.1603 14.23 10.6903 14.63 10.5903 15.29L9.48031 15.09C9.66031 13.93 10.6003 13.18 11.8503 13.18C13.1003 13.18 14.0403 13.93 14.0403 15C14.0403 15.58 13.7003 16.05 13.1803 16.34V16.36C13.8103 16.63 14.2203 17.22 14.2203 18.02C14.2203 19.17 13.2803 20.03 11.9003 20.03C10.4203 20.03 9.48031 19.17 9.32031 17.7V17.7Z"
      fill={fill}
    />
    <path
      d="M15.0303 16.61C15.0303 14.48 16.1403 13.19 17.7403 13.19C19.3403 13.19 20.4503 14.49 20.4503 16.61C20.4503 18.73 19.3403 20.03 17.7403 20.03C16.1403 20.03 15.0303 18.73 15.0303 16.61V16.61ZM19.2803 16.61C19.2803 15.07 18.6903 14.28 17.7403 14.28C16.7903 14.28 16.2003 15.07 16.2003 16.61C16.2003 18.15 16.7903 18.94 17.7403 18.94C18.6903 18.94 19.2803 18.15 19.2803 16.61V16.61Z"
      fill={fill}
    />
  </svg>
);
FastForwardThirtyIcon.propTypes = {
  fill: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
  width: PropTypes.string,
  height: PropTypes.string,
  dataTestId: PropTypes.string,
  viewBox: PropTypes.string,
};
export default FastForwardThirtyIcon;
