import classNames from 'clsx';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import cssVars from 'src/common/styles/variables';
import { ENABLE_PLAYBACK_SPEED_CONTROL } from '../../../constants/experiments/player';
import withMouseHover from '../../../decorators/hovers/withMouseHover';
import useExperiment from '../../../hooks/useExperiment';
import { selectIsFord, selectIsMobile } from '../../../selectors/app';
import { selectIsVideoAdDialogOpen } from '../../../selectors/dialog';
import {
  selectCanChangePlaybackRate,
  selectNowPlaying,
} from '../../../selectors/player';
import { getCssStyle } from '../../../utils/getCssStyle';
import FavoriteControl from '../../shared/controls/favorite/FavoriteControl';
import FavoriteStyleContext from '../../shared/controls/favorite/context/FavoriteStyleContext';
import ShareDialog from '../../shared/dialog/ShareDialog';
import ShareIcon from '../../shared/svgIcons/player/ShareIcon';
import { VolumeControl } from '../controls/volume';
import { DiscordControls } from './DiscordControls';
import SpeedControl from './SpeedControl';
import css from './action-drawer.module.scss';

function ActionDrawer({
  tunedGuideId,
  sharingFromPlayer,
  isDiscord,

  // withMouseHover
  handleMouseEnter,
  handleMouseLeave,
}) {
  const [isShowingShareDialog, setIsShowingShareDialog] = useState(false);
  const canChangePlaybackRate = useSelector(selectCanChangePlaybackRate);
  const isPlaybackRateExperimentEnabled = useExperiment(
    ENABLE_PLAYBACK_SPEED_CONTROL,
  );
  const isVideoAdDialogOpen = useSelector(selectIsVideoAdDialogOpen);
  const isMobile = useSelector(selectIsMobile);
  const isFord = useSelector(selectIsFord);
  const {
    share: { canShare, shareUrl, shareText } = {},
    image,
    subtitle,
    title,
  } = useSelector(selectNowPlaying);
  const shareMeta = {
    guideId: tunedGuideId,
    image,
    subtitle,
    title,
    shareUrl,
    shareText,
  };

  function toggleShareDialog() {
    setIsShowingShareDialog((value) => !value);
  }

  useEffect(() => {
    if (isShowingShareDialog) {
      sharingFromPlayer();
    }
  }, [isShowingShareDialog, sharingFromPlayer]);

  return (
    <div
      className={classNames(css.actionDrawerContainer, {
        [css.noGuideId]: !tunedGuideId,
      })}
    >
      {isDiscord && !isMobile && !isFord && (
        <div className={classNames(css.spacer)}>
          <DiscordControls />
        </div>
      )}
      {tunedGuideId &&
        isPlaybackRateExperimentEnabled &&
        canChangePlaybackRate &&
        !isFord && (
          <div className={classNames(css.spacer, css.speedControlSpacer)}>
            <SpeedControl
              iconContainerClass={classNames(
                css.actionDrawerIconContainer,
                css.speedControlContainer,
              )}
            />
          </div>
        )}
      {!isFord && (
        <div className={classNames(css.spacer)}>
          <VolumeControl
            iconContainerClass={css.actionDrawerIconContainer}
            volumeContainerClass={css.volumeContainer}
            isDisabled={isVideoAdDialogOpen}
          />
        </div>
      )}
      {canShare && !isDiscord && (
        <>
          <div id="playerShareContainer" className={classNames(css.spacer)}>
            <div
              id="playerShare"
              data-testid="playerShareButton"
              className={classNames(css.actionDrawerIconContainer)}
              onClick={toggleShareDialog}
            >
              <ShareIcon
                width={cssVars['--large-icon-size']}
                height={cssVars['--large-icon-size']}
              />
            </div>
          </div>
          <ShareDialog
            shareMeta={shareMeta}
            showEmbed
            isActive={isShowingShareDialog}
            toggleShareDialog={toggleShareDialog}
          />
        </>
      )}
      {tunedGuideId && (!isDiscord || !isMobile) && (
        <div
          id="playerFavoriteContainer"
          className={classNames(css.spacer, css.favoriteSpacer)}
        >
          <div id="playerFavorite" className={css.actionDrawerIconContainer}>
            <FavoriteStyleContext.Provider
              value={{ handleMouseEnter, handleMouseLeave }}
            >
              <FavoriteControl
                fill={getCssStyle('--text-color')}
                hoverFill={getCssStyle('--knighthood-grey')}
              />
            </FavoriteStyleContext.Provider>
          </div>
        </div>
      )}
    </div>
  );
}

ActionDrawer.propTypes = {
  sharingFromPlayer: PropTypes.func.isRequired,
  tunedGuideId: PropTypes.string.isRequired,
  isDiscord: PropTypes.bool,

  // withMouseHover
  handleMouseEnter: PropTypes.func.isRequired,
  handleMouseLeave: PropTypes.func.isRequired,
};

export default withMouseHover(ActionDrawer);
