import classNames from 'clsx';
import flow from 'lodash/flow';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import boost from 'src/common/constants/analytics/categoryActionLabel/boost';
import { WEB_OPEN_INSTALL_APP_DIALOG_SHOW_PLAYER } from 'src/common/constants/experiments/dialog';
import * as localization from 'src/common/constants/localizations/player';
import { playerStatuses } from 'src/common/constants/playerStatuses';
import cssVars from 'src/common/styles/variables';
import { getBoostNextPlayButtonWidth } from 'src/common/utils/boost';
import { isMedium, isSmall } from 'src/common/utils/breakpoints';
import {
  logBoostDisabledAction,
  logBoostEnabledAction,
} from '../../actions/logging';
import {
  playBoostStation,
  retuneDirectoryStation,
  seek,
  setHasAdBlocker,
} from '../../actions/player';
import { sharingFromPlayer } from '../../actions/share';
import connectWithExperiments from '../../decorators/connectWithExperiments';
import {
  selectDiscordState,
  selectIsDiscord,
  selectIsFord,
  selectIsMobile,
} from '../../selectors/app';
import { selectIsDesktopNowPlayingEnabled } from '../../selectors/config';
import {
  selectCanScrub,
  selectGuideItemPathname,
  selectHasAdBlocker,
  selectIsBoostFeaturedInPlayer,
  selectIsBoostStation,
  selectIsMediaAdLoaded,
  selectNowPlaying,
  selectNowPlayingRejectReasonKey,
  selectPlayerStatus,
  selectPositionInfo,
  selectTunedGuideId,
} from '../../selectors/player';
import cssVariables from '../../styles/variables';
import { isDiscordMode } from '../../utils/discord';
import shouldShowPlayer from '../../utils/playerStatus/shouldShowPlayer';
import { showAppToastInPlayer } from '../../utils/showAppToastInPlayer';
import Text from '../Text';
import MessagePopover from '../messagePopover/MessagePopover';
import FavoriteControl from '../shared/controls/favorite/FavoriteControl';
import FastForwardFifteenIcon from '../shared/svgIcons/FastForwardFifteenIcon';
import FastForwardThirtyIcon from '../shared/svgIcons/FastForwardThirtyIcon';
import RewindFifteenIcon from '../shared/svgIcons/RewindFifteenIcon';
import BoostNextPlayIcon from '../shared/svgIcons/player/BoostNextPlayIcon';
import PlayErrorIcon from '../shared/svgIcons/player/PlayErrorIcon';
import isAdBlockEnabled from '../utils/isAdBlockEnabled';
import ActionDrawer from './actionDrawer/ActionDrawer';
import PlayButton from './controls/playButton/PlayButton';
import Scrubber from './controls/scrubber/Scrubber';
import NowPlaying from './nowPlaying/NowPlaying';
import css from './player.module.scss';

export function mapStateToProps(state) {
  const { duration, elapsedSeconds = 0 } = selectPositionInfo(state);

  return {
    duration,
    elapsedSeconds,
    canScrub: selectCanScrub(state),
    isMobile: selectIsMobile(state),
    isDiscord: selectIsDiscord(state),
    discordState: selectDiscordState(state),
    isFord: selectIsFord(state),
    isBoostFeaturedInPlayer: selectIsBoostFeaturedInPlayer(state),
    isBoostStation: selectIsBoostStation(state),
    nowPlaying: selectNowPlaying(state),
    guideItemPathname: selectGuideItemPathname(state),
    playerStatus: selectPlayerStatus(state),
    tunedGuideId: selectTunedGuideId(state),
    hasAdBlocker: selectHasAdBlocker(state),
    isMediaAdLoaded: selectIsMediaAdLoaded(state),
    isDesktopNowPlayingEnabled: selectIsDesktopNowPlayingEnabled(state),
    nowPlayingRejectReasonKey: selectNowPlayingRejectReasonKey(state),
    isPlayerOpen: shouldShowPlayer(state),
    showAppToast: showAppToastInPlayer(state),
  };
}

export function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        seek,
        setHasAdBlocker,
        sharingFromPlayer,
        logBoostEnabledAction,
        logBoostDisabledAction,
        playBoostStation,
        retuneDirectoryStation,
      },
      dispatch,
    ),
  };
}

const playerErrorIconSize = isDiscordMode() ? '12px' : '16px';

export const ErrorMessage = ({ customMessage }) => (
  <div data-testid="playerErrorMessage" className={css.informational}>
    <div className={css.errorIcon}>
      <PlayErrorIcon width={playerErrorIconSize} height={playerErrorIconSize} />
    </div>
    <div className={css.errorMessageContainer}>
      {customMessage ? (
        <Text name={customMessage} />
      ) : (
        <Text name={localization.ERROR_MESSAGE} />
      )}
    </div>
  </div>
);

ErrorMessage.propTypes = {
  customMessage: PropTypes.string,
};

export const PopOutMessage = () => (
  <div data-testid="playerPopOutMessage" className={css.informational}>
    <Text name={localization.POP_OUT_MESSAGE} />
  </div>
);

export class Player extends Component {
  static propTypes = {
    canScrub: PropTypes.bool.isRequired,
    isMobile: PropTypes.bool.isRequired,
    actions: PropTypes.object.isRequired,
    isBoostStation: PropTypes.bool.isRequired,
    isBoostFeaturedInPlayer: PropTypes.bool.isRequired,
    nowPlaying: PropTypes.object.isRequired,
    isDiscord: PropTypes.bool.isRequired,
    isFord: PropTypes.bool.isRequired,
    discordState: PropTypes.object.isRequired,
    isMediaAdLoaded: PropTypes.bool.isRequired,
    guideItemPathname: PropTypes.string,
    playerStatus: PropTypes.string.isRequired,
    tunedGuideId: PropTypes.string,
    breakpoint: PropTypes.number.isRequired,
    duration: PropTypes.number.isRequired,
    elapsedSeconds: PropTypes.number.isRequired,
    nextPlayButtonRef: PropTypes.object.isRequired,
    hasAdBlocker: PropTypes.bool,
    [WEB_OPEN_INSTALL_APP_DIALOG_SHOW_PLAYER]: PropTypes.bool,
    isDesktopNowPlayingEnabled: PropTypes.bool,
    disablePreroll: PropTypes.bool,
    isPlayerOpen: PropTypes.bool.isRequired,
    showAppToast: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);

    // NOTE: shouldAllowSwitch governs if the user will be able to see the boost icon onHover of the player tile artwork
    // and also controls if a click on the player station artwork will process their boost request
    this.state = {
      shouldAllowSwitch: false,
    };
  }

  componentDidMount() {
    isAdBlockEnabled(this.isAdBlockEnabledCallback);
  }

  componentDidUpdate(prevProps) {
    const { shouldAllowSwitch } = this.state;
    const { actions, isBoostFeaturedInPlayer, nowPlaying, playerStatus } =
      this.props;
    const currentStationHasBoostAvail =
      this.hasBoostStationAvailable(nowPlaying);
    const previousStationHadBoostAvail = this.hasBoostStationAvailable(
      prevProps.nowPlaying,
    );

    // NOTE: We do not want to allow Switch until initial playback has occurred, as it could cause potential race conditions
    // If all the following conditions are met, we want to allow the user to boost:
    // * If the currently played station has boost hydrated in their nowPlaying response
    // * If playerStatus has reached a playing state. shouldAllowSwitch is set to false on any content switching,
    //   the app waits until it has received some playback to avoid allowing the user to switch while a potential preroll is loading
    if (
      currentStationHasBoostAvail &&
      !shouldAllowSwitch &&
      playerStatus === playerStatuses.playing
    ) {
      this.setState({
        shouldAllowSwitch: true,
      });
    }

    // NOTE: Scenarios where we don't want to allow clicking the station artwork to initiate a boost:
    // * Immediately after isBoostFeaturedInPlayer is changed (user has just clicked to initiate or end a boost exp).
    //   When isBoostFeaturedInPlayer changes, we set shouldAllowSwitch to false and wait for playback before allowing switching again
    // * If the user navigates from a boost eligible station to a non-boost eligible station, we want to reset shouldAllowSwitch to false.
    //   This is to keep the state clean, since the user is not actively engaging with the boost feature. The nowPlaying comparison handles that.
    if (currentStationHasBoostAvail || previousStationHadBoostAvail) {
      if (
        shouldAllowSwitch &&
        (isBoostFeaturedInPlayer !== prevProps.isBoostFeaturedInPlayer ||
          nowPlaying !== prevProps.nowPlaying)
      ) {
        this.setState({
          shouldAllowSwitch: false,
        });
      }
    }

    if (
      nowPlaying?.primaryGuideId &&
      nowPlaying?.primaryGuideId !== prevProps?.nowPlaying?.primaryGuideId
    ) {
      if (this.hasBoostStationAvailable(nowPlaying)) {
        actions.logBoostEnabledAction(boost.labels.swipe);
        actions.logBoostEnabledAction(boost.labels.button);
      } else {
        actions.logBoostDisabledAction(boost.labels.swipe);
        actions.logBoostDisabledAction(boost.labels.button);
      }
    }
  }

  hasBoostStationAvailable = (nowPlaying) => {
    const { isDiscord } = this.props;

    return !isDiscord && !isEmpty(nowPlaying?.boost);
  };

  isAdBlockEnabledCallback = (hasAdBlocker) => {
    const { actions } = this.props;
    actions.setHasAdBlocker(hasAdBlocker);
  };

  handleSeek = (secondsToSeek) => {
    const { actions, duration, elapsedSeconds } = this.props;
    const positionPercent = ((elapsedSeconds + secondsToSeek) / duration) * 100;
    actions.seek(positionPercent > 0 ? positionPercent : 0);
  };

  handleBoostSwitch = (boostActionLabel) => () => {
    const { isBoostFeaturedInPlayer } = this.props;

    if (this.state.shouldAllowSwitch) {
      const { actions } = this.props;
      if (isBoostFeaturedInPlayer) {
        // retuneDirectoryStation is the entry point when returning from a boost station to a direction station
        actions.retuneDirectoryStation(boostActionLabel);
      } else {
        // playBoostStation is the entry point for switching to a boost station
        actions.playBoostStation(boostActionLabel);
      }
    }
  };

  render() {
    const {
      actions,
      isBoostStation,
      isBoostFeaturedInPlayer,
      canScrub,
      nowPlaying,
      guideItemPathname,
      isMobile,
      playerStatus,
      tunedGuideId,
      hasAdBlocker,
      isMediaAdLoaded,
      breakpoint,
      [WEB_OPEN_INSTALL_APP_DIALOG_SHOW_PLAYER]: showPlayerOverDialog,
      isDesktopNowPlayingEnabled,
      disableTitleScroll,
      disableScrubber,
      showFavoriteIcon,
      controlsClassName,
      playButtonContainerClassName,
      disableActionsContainer,
      disablePreroll,
      nextPlayButtonRef,
      isDiscord,
      isFord,
      discordState,
      nowPlayingRejectReasonKey,
      isPlayerOpen,
      showAppToast,
    } = this.props;

    if (!tunedGuideId && !(isDiscord && isPlayerOpen)) {
      return null;
    }

    let StatusMessage;
    let scrubberContent =
      canScrub || !isDiscord ? <Scrubber className={css.scrubber} /> : null;
    const isSmallBreakpoint = isSmall(breakpoint);
    const isMediumBreakpoint = isMedium(breakpoint);
    const showDoubleSeekButtonBreakpoint = isDiscord
      ? isSmallBreakpoint
      : isMediumBreakpoint;
    const hasBoostAvailable = this.hasBoostStationAvailable(nowPlaying);
    const boostNextPlayButtonWidth =
      getBoostNextPlayButtonWidth(isMediumBreakpoint);

    const FastForwardIcon = showDoubleSeekButtonBreakpoint
      ? FastForwardFifteenIcon
      : FastForwardThirtyIcon;

    if (playerStatus === playerStatuses.failed) {
      const errorMessage = (
        <div className={css.messageContainer}>
          <ErrorMessage
            customMessage={
              hasAdBlocker ? localization.AD_BLOCKER_ERROR_MESSAGE : ''
            }
          />
        </div>
      );
      scrubberContent = errorMessage;
      StatusMessage = errorMessage;
    } else if (playerStatus === playerStatuses.popout) {
      scrubberContent = (
        <div className={css.messageContainer}>
          <PopOutMessage />
        </div>
      );
      StatusMessage = <PopOutMessage />;
    }

    return (
      <div
        data-testid="player"
        className={classNames(css.playerContainer, {
          [css.playerHighestIndex]: showPlayerOverDialog,
          [css.desktopPlayerContainer]: isDesktopNowPlayingEnabled,
          [css.noGuideId]: !tunedGuideId,
        })}
      >
        <div
          className={classNames(css.leftSection, {
            [css.hide]: isDiscord && !tunedGuideId,
          })}
        >
          {StatusMessage && (
            <div className={css.leftErrorMessageContainer}>{StatusMessage}</div>
          )}
          <div
            data-testid="nowPlayingContainer"
            data-guideid={tunedGuideId}
            className={classNames(css.nowPlayingContainer, {
              [css.hideSmall]: !!StatusMessage,
            })}
          >
            <NowPlaying
              isBoostStation={isBoostStation}
              isBoostFeaturedInPlayer={isBoostFeaturedInPlayer}
              shouldAllowSwitch={this.state.shouldAllowSwitch}
              hasBoostAvailable={hasBoostAvailable}
              handleBoostClick={this.handleBoostSwitch(boost.labels.swipe)}
              nowPlaying={nowPlaying}
              nowPlayingPathname={guideItemPathname}
              isMobile={isMobile}
              isMediaAdLoaded={isMediaAdLoaded}
              disableTitleScroll={disableTitleScroll}
            />
          </div>
        </div>
        <div
          className={classNames(css.controlsContainer, controlsClassName, {
            [css.hide]: isDiscord && !tunedGuideId,
            [css.isLive]: !canScrub,
            [css.failed]: playerStatus === playerStatuses.failed,
          })}
        >
          {showAppToast && <MessagePopover className={css.playerMessage} />}
          <div className={css.controls}>
            {showFavoriteIcon && (
              <FavoriteControl fill={cssVariables['--disabled-grey']} />
            )}
            {hasBoostAvailable && (
              <div
                className={css.boostNextPlayIconContainer}
                ref={nextPlayButtonRef}
              >
                <BoostNextPlayIcon
                  backgroundfill={
                    this.state.shouldAllowSwitch
                      ? cssVars['--t-sharp']
                      : cssVars['--disabled-t-sharp']
                  }
                  width={`${boostNextPlayButtonWidth}px`}
                  height={`${boostNextPlayButtonWidth}px`}
                  onClick={this.handleBoostSwitch(boost.labels.button)}
                />
              </div>
            )}
            {canScrub &&
              !isMediaAdLoaded &&
              showDoubleSeekButtonBreakpoint &&
              !isFord && (
                <RewindFifteenIcon
                  onClick={() => this.handleSeek(-15)}
                  className={css.rewindContainer}
                />
              )}
            <div
              className={classNames(
                css.playButtonContainer,
                playButtonContainerClassName,
                {
                  [css.isDisabled]:
                    isDiscord &&
                    playerStatus !== playerStatuses.failed &&
                    (!discordState?.canControlPlayback ||
                      nowPlayingRejectReasonKey),
                },
              )}
            >
              <PlayButton
                breakpoint={breakpoint}
                disablePreroll={disablePreroll}
                isLive={canScrub}
                className={classNames(css.playButton, {
                  [css.isLive]: !canScrub,
                  [css.failed]: playerStatus === playerStatuses.failed,
                })}
                enableDiscordControlTooltip={
                  playerStatus !== playerStatuses.failed
                }
              />
            </div>
            {canScrub && !isMediaAdLoaded && !isFord && (
              <FastForwardIcon
                onClick={() =>
                  this.handleSeek(showDoubleSeekButtonBreakpoint ? 15 : 30)
                }
                className={css.fastForwardContainer}
              />
            )}
          </div>
          {!disableScrubber && !isFord && scrubberContent}
        </div>
        {!disableActionsContainer && (
          <ActionDrawer
            sharingFromPlayer={actions.sharingFromPlayer}
            tunedGuideId={tunedGuideId}
            isDiscord={isDiscord}
          />
        )}
      </div>
    );
  }
}

export default flow(
  connect(mapStateToProps, mapDispatchToProps),
  connectWithExperiments([WEB_OPEN_INSTALL_APP_DIALOG_SHOW_PLAYER]),
)(Player);
