import classNames from 'clsx';
import PropTypes from 'prop-types';
import { Tooltip as ReactTooltip } from 'react-tooltip';

import css from './tooltip.module.scss';

/**
 *
 * @param tooltipId required, corresponds to data-for attribute in target element
 * -  target should also have data-tip attribute, value is tooltip text
 * @param tooltipClassName add styling
 * @param tooltipPosition one of "top", "bottom", "left", "right"
 * @param tooltipEffect either "solid" (stationary) or "float" (follows mouse)
 *
 * Note: in case of trouble positioning, target should contain Tooltip and include
 *       the following styles- transform: translate(0, 0); position: relative;
 */

const TooltipLegacy = ({
  tooltipId,
  tooltipClassName,
  tooltipPosition,
  tooltipEffect,
}) => (
  <ReactTooltip
    id={tooltipId}
    className={classNames(css.tooltip, tooltipClassName)}
    place={tooltipPosition || 'bottom'}
    effect={tooltipEffect || 'solid'}
  />
);

TooltipLegacy.propTypes = {
  tooltipId: PropTypes.string.isRequired,
  tooltipClassName: PropTypes.string,
  tooltipPosition: PropTypes.string,
  tooltipEffect: PropTypes.string,
};

export default TooltipLegacy;
