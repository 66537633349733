import { desktopFuncNames } from '../../constants/desktop';
import invokeDesktopFunction from '../desktop/invokeDesktopFunction';
import isDesktop from '../desktop/isDesktop';
import getWindowConfiguration from './getWindowConfiguration';

export default function openSocialShareWindow(windowOptions) {
  const {
    specs,
    options: { url, target },
  } = getWindowConfiguration(windowOptions);

  if (isDesktop()) {
    invokeDesktopFunction(desktopFuncNames.openSocialShare, url);
    return null;
  }
  return window.open(url, target, specs);
}
