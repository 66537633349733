import url from 'url';

import {
  SHARE_TITLE_TUMBLR,
  SHARE_TITLE_TWITTER,
} from '../../../common/constants/localizations/profile';
import vars from '../../../common/vars.js';
import { sharePlatformNames } from '../../constants/share/platforms';
import getFullUrl from '../../utils/fullUrl.js';

export default function getShareUrl(
  getLocalizedText,
  shareMeta,
  sharePlatformName,
) {
  const tuneinUrl = getFullUrl('/');
  const title = shareMeta.title;
  const shareUrl = shareMeta.shareUrl;
  let text = shareMeta.shareText;

  const urlObjBase = {
    protocol: 'https:',
  };
  let urlObject;

  switch (sharePlatformName) {
    case sharePlatformNames.FACEBOOK:
      urlObject = {
        ...urlObjBase,
        host: 'facebook.com',
        pathname: '/dialog/share',
        query: {
          app_id: vars.get('FB_CID'),
          href: shareUrl,
        },
      };
      return url.format(urlObject);

    case sharePlatformNames.TWITTER:
      text = getLocalizedText(SHARE_TITLE_TWITTER).replace('{title}', title);
      urlObject = {
        ...urlObjBase,
        host: 'twitter.com',
        pathname: '/intent/tweet',
        query: {
          url: shareUrl,
          text,
        },
      };
      return url.format(urlObject);

    case sharePlatformNames.TUMBLR:
      text = getLocalizedText(SHARE_TITLE_TUMBLR).replace('{title}', title);
      urlObject = {
        ...urlObjBase,
        host: 'www.tumblr.com',
        pathname: '/widgets/share/tool',
        query: {
          posttype: 'link',
          content: shareUrl,
          title,
          caption: text,
          url: tuneinUrl,
          _format: 'html',
        },
      };
      return url.format(urlObject);

    default:
      return getFullUrl('/errors/');
  }
}
