import classNames from 'clsx';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import assetUrl from '../../utils/assetUrl';
import css from './imageWithDefault.module.scss';

export const defaultImageSrc = assetUrl('assets/img/default-item-v2.png');

/**
 *
 * @param src {string} URL that resolves image file
 * @param placeholderSrc {string} URL that resolves placeholder image file
 * @param alt {string} text description of image file
 * @param id {string} unique element id
 * @param dataTestId {string} unique test id for automation tests
 * @param style {object} CSS styles to be applied to element
 * @param containerClassName {string} class names to be applied to LazyLoad container
 * @param className {string} class names to be applied to element
 * @param onError {function} optional image error callback
 * @param disableLazyLoad {boolean} flag that disables image lazy loading
 */
const ImageWithDefault = ({
  src,
  placeholderSrc = defaultImageSrc,
  alt,
  id,
  dataTestId,
  style = {},
  containerClassName,
  className,
  onError,
  disableLazyLoad = false,
  useDefaultImage = false,
}) => {
  const [imageSrc, setImageSrc] = useState(
    useDefaultImage ? defaultImageSrc : src,
  );

  useEffect(() => {
    if (src) {
      setImageSrc(src);
    }
  }, [src]);

  if (!imageSrc) return null;

  const handleError = () => {
    if (imageSrc !== placeholderSrc) {
      setImageSrc(placeholderSrc);
      if (onError) onError();
    }
  };

  return (
    <div className={classNames(containerClassName, css.container)}>
      <img
        id={id}
        data-testid={dataTestId}
        className={classNames(css.image, className)}
        style={style}
        src={imageSrc}
        alt={alt}
        onError={handleError}
        loading={disableLazyLoad ? 'eager' : 'lazy'}
      />
    </div>
  );
};

ImageWithDefault.propTypes = {
  dataTestId: PropTypes.string,
  id: PropTypes.string,
  style: PropTypes.object,
  containerClassName: PropTypes.string,
  className: PropTypes.string,
  alt: PropTypes.string,
  src: PropTypes.string,
  disableLazyLoad: PropTypes.bool,
};

export default ImageWithDefault;
